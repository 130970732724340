<template>
  <vuestic-widget :headerText="headerTitle" class="twilioPage" v-bind:class="{ successPage: success }">
    <ValidationObserver   v-slot="{ handleSubmit }" ref="twilioForm">
      <form class="twilio-form" @submit.prevent="handleSubmit(saveTwilio)">
        <template v-if="!success">
          <div class="row">
            <div class="col-md-12">
              <div class="alert alert-primary title" role="alert">
                Before You Can Use The System You Must Add Your Twilio Credentials - Please Watch The Video Below.
              </div>
              <div class="mb-2 text-danger"><b>IMPORTANT</b></div>
              <div>If you have an existing Twilio account that you use for other products and services be sure to create a <span class="text-primary font-weight-bold">"Sub Account"</span> for RingBot. <br/>You can do that by clicking in the upper right hand corner of Twilio.</div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12 video-tutorial mt-4 mb-2">
              <span>Learn how to setup Twilio in your agency account</span>
              <a href="javascript:;" class="video-link"><img height="50px" src="@/assets/images/video-play.webp" @click.prevent="openVideoModal"></a>
            </div>
          </div>
          <div class="mt-4">
            <div class="row mt-4">
              <div class="col-md-6 col-sm-12">
                <text-input
                  :immediate="false"
                  name="aSID"
                  v-model="twilioForm.aSID"
                  :validate="'required'"
                  label="Twilio Account SID"
                  tooltip="Twilio Account SID"
                />
              </div>
              <div class="col-md-6 col-sm-12">
                <text-input
                  :immediate="false"
                  name="token"
                  v-model="twilioForm.token"
                  :validate="'required'"
                  label="Twilio Auth Token"
                  tooltip="Twilio Auth Token"
                />
              </div>
            </div>
            <div class="row">
              <div class="col-md-12 text-center">
                <button class="btn btn-primary confirm-button mt-4">
                  <span v-if="!loading">
                    Confirm
                  </span>
                  <atom-spinner v-else slot="loading" :animation-duration="1500" :size="20" color="#fff" style="margin: auto" />
                </button>
              </div>
            </div>
          </div>
        </template>
        <div v-else class="mt-4 text-center">
          <div class="alert alert-primary title text-center" role="alert">Your Twilio credentials have been set up and your are ready to go. Next step is to add your marketing agency as the first business. Be sure to use a unique email address for your agency account.</div>
          <div class="success-title"><i class="fa fa-thumbs-up" aria-hidden="true"></i> You're all set!</div>
          <div class="col ml-auto mr-auto">
            <router-link :to="{name: 'businessAccountAdd'}" class="btn btn-primary"><span aria-hidden="true" class="fa fa-plus" style="font-size: 1rem;"></span>&nbsp;New Business
            </router-link>
          </div>
          <div class="clearfix"></div>
        </div>
      </form>
    </ValidationObserver>

    <!-- modal show instrucion video to user-->
    <div class="modal fade" ref="videoModal" tabindex="-1" role="dialog">
      <div class="modal-dialog modal-lg"  role="document">
        <div class="modal-content" >
          <div class="modal-header">
            <h5 class="modal-title">How to Setup Agency Account?</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body" />
        </div>
      </div>
    </div>
  </vuestic-widget>
</template>

<script>
  export default {
    name: 'agency-setup-wizard',
    components: {
    },
    data() {
      return {
        success: false,
        loading: false,
        passwordForm: {
          password: ''
        },
        companyForm: {
          name: ''
        },
        twilioForm: {
          aSID: '',
          sSID: ''
        }
      };
    },
    mounted() {
      this.companyForm.id = this.$store.state.auth.user.agency.id
      // this.twilioForm.id = this.$store.state.auth.user.agency.twilioConfig.id

      const modal = $(this.$refs.videoModal);
      // eslint-disable-next-line no-useless-escape
      modal.on('show.bs.modal', () => modal.find('.modal-body').html('<div style="padding:56.25% 0 0 0;position:relative;"><iframe src="https://player.vimeo.com/video/697046049?autoplay=1&loop=1&title=0&byline=0&portrait=0" style="position:absolute;top:0;left:0;width:100%;height:100%;" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe></div><script src="https://player.vimeo.com/api/player.js"><\/script>'))
        .on('hidden.bs.modal', (e) => modal.find('.modal-body').html(''));
    },
    computed: {
      headerTitle() {
        return this.success ? 'Add a New Business' : 'Twilio Setup Wizard';
      }
    },
    methods: {
      saveTwilio() {
        this.loading = true;
        return new Promise((resolve,reject) => {
          this.$refs.twilioForm.validate().then(success => {
            if (!success) {
              this.loading = false;
              return resolve(false)
            }

            this.$store.dispatch('agency/setupTwilioConfig', this.twilioForm)
              .then(response => {
                this.$store.dispatch('auth/getProfile')
                this.success = true;
                this.loading = false;
                resolve(true)
              }).catch(e => {
                this.loading = false;
                reject(e)
              })
          });
        });
      },
      openVideoModal () {
        $(this.$refs.videoModal).modal('show')
      },
    }
  };
</script>

<style scoped lang="scss">
  .twilioPage {
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
    border: 1px solid #c1c5cf;
    &.successPage {
      width: auto;
    }
  }
  .twilio-form {
    .title {
      font-weight: bold;
      font-size: 16px;
    }
    .video-tutorial {
      span {
        font-size: 17px;
        font-weight: bold;
        color: #717880;
        padding: 0;
      }
    }
    .confirm-button {
      text-align: center;
      min-width: 130px;
      font-size: 20px;
      padding: 10px 20px;
    }
    .success-title {
      font-size: 25px;
      margin-bottom: 10px;
    }
  }
  .video-link {
    display: inline-block;
    margin-left: 20px;
    transition: transform .2s; /* Animation */
    &:hover{
      transform: scale(1.05);
    }
  }
  .modal-header {
    display: flex;
  }
  .modal-content .modal-body {
    padding: 20px 0 0;
  }
  .modal.show .modal-dialog {
    top: 50% !important;
    transform: translateY(-50%) !important;
    margin: 0 auto !important;
  }
</style>
